//auth
export const SET_LOGIN = "LOGIN";
export const SET_CREATE_ACCOUNT = "CREATE_ACCOUNT";
export const SET_FORGOT_PWD = "FORGOT_PWD";
export const SET_VALIDATE_OTP = "VALIDATE_OTP";
export const SET_CUSTOMER_LOGOUT = "CUSTOMER_LOGOUT";

//admin
export const SET_ADDARTIST = "ADDARTIST";

export const SET_GETALLORDERS = "GETALLORDERS";
export const SET_UPDATESTATUSORDER = "UPDATESTATUSORDER";
export const SET_GETDELIVEREDORDERS = "GETDELIVEREDORDERS";
export const SET_GETCANCELLEDORDERS = "GETCANCELLEDORDERS";
export const SET_GETORDEROFCUSTOMER = "GETORDEROFCUSTOMER";

export const SET_ASSIGN = "ASSIGN";

export const SET_CREATEPRODUCT = "CREATEPRODUCT";
export const SET_EDITSTOCK = "EDITSTOCK";
export const SET_GETPRODUCT = "GETPRODUCT";
export const SET_GETPRODUCTBYID = "GETPRODUCTBYID";
export const SET_GETPRODUCTBYCATEGORY = "GETPRODUCTBYCATEGORY";
export const SET_GETSELECTEDPRODUCT = "GETSELECTEDPRODUCT";

export const SET_GETALLCUSTOMERS = "GETALLCUSTOMERS";
export const SET_GETCUSTOMERDETAILSADMIN = "GETCUSTOMERDETAILSADMIN";

export const SET_GETALLAPPROVEDARTISTS = "GETALLAPPROVEDARTISTS";
export const SET_ARTISTAPPROVEBYADMIN = "ARTISTAPPROVEBYADMIN";
export const SET_ARTISTREJECTBYADMIN = "ARTISTREJECTBYADMIN";
export const SET_GETPENDINGARTISTS = "GETPENDINGARTISTS";
export const SET_GETARTISTS = "GETARTISTS";
export const SET_GETREJECTEDARTISTS = "GETREJECTEDARTISTS";

export const SET_GETAPPOINTMENTBYID = "GETAPPOINTMENTBYID";
export const SET_GETARTISTDETAILSBYID = "GETARTISTDETAILSBYID";
export const SET_GETUPDATEAPPOINTMENTDATE = "UPDATEAPPOINTMENTDATE";
export const SET_GETUNASSIGNEDAPPOINTMENTS = "GETUNASSIGNEDAPPOINTMENTS";
export const SET_GETCANCELLEDAPPOINTMENTS = "GETCANCELLEDAPPOINTMENTS";
export const SET_GETCOMPLETEDAPPOINTMENTS = "GETCOMPLETEDAPPOINTMENTS";
export const SET_CANCELAPPOINTMENT = "CANCELAPPOINTMENT";
export const SET_GETPACKAGEDETAILSADMIN = "GETPACKAGEDETAILSADMIN";
export const SET_SUBMITAPPOINTMENTDETAILSBYADMIN =
  "SUBMITAPPOINTMENTDETAILSBYADMIN";

export const SET_CONTACTADMIN = "CONTACTADMIN";

//artist
export const SET_GETARTIST = "GETARTIST";
export const SET_ARTISTAVAILABILITY = "ARTISTAVAILABILITY";
export const SET_ARTISTPROFILE = "ARTISTPROFILE";
export const SET_GETPACKAGEDETAILS = "GETPACKAGEDETAILS";
export const SET_GETPACKAGEDETAILSLOADING = "SET_GETPACKAGEDETAILSLOADING";
export const SET_PACKAGEDETAILSLOADINGERROR = "SET_PACKAGEDETAILSLOADINGERROR";
export const SET_APPOINTMENTDETAILS = "APPOINTMENTDETAILS";
export const SET_SUBMITAPPOINTMENTDETAILS = "SUBMITAPPOINTMENTDETAILS";
export const SET_ADDPERSONWITHPACKAGE = "ADDPERSONWITHPACKAGE";
export const SET_UPDATEARTISTPROFILE = "UPDATEARTISTPROFILE";

//CUTOMER
export const SET_ADDRESS = "ADDRESS";
export const SET_CUSTOMERPROFILE = "CUSTOMERPROFILE";
export const SET_GETESTIMATIONCHARGES = "GETESTIMATIONCHARGES";
export const SET_GETENQUIRYFORM = "GETENQUIRYFORM";
export const SET_ARTISTRATING = "ARTISTRATING";
export const SET_GETCUSTOMERORDER = "GETCUSTOMERORDER";
export const SET_ESTIMATIONDETAILSTOGUEST = "GETESTIMATIONDETAILSTOGUEST";
export const SET_BOOKAPPOINTMENTNEW = "GETBOOKAPPOINTMENTNEW";

export const SET_EVENTBASED = "EVENTBASED";

export const PENDING_PAYMENT_REQUESTED = "PENDING_PAYMENT_REQUESTED";
export const PENDING_PAYMENT_FULFILLED = "PENDING_PAYMENT_FULFILLED";
export const PENDING_PAYMENT_REJECTED = "PENDING_PAYMENT_REJECTED";

//RAZORPAY

export const SET_GETRAZORPAYKEY = "GETRAZORPAYKEY";
export const SET_BOOKAPPOINTMENT = "BOOKAPPOINTMENT";
export const SET_CREATEBOOKINGAPPOINTMENTPAYMENT =
  "CREATEBOOKINGAPPOINTMENTPAYMENT";
export const SET_FINALAPPOINTMENTPAYMENT = "FINALAPPOINTMENTPAYMENT";
export const SET_PAYAPPOINTMENTORDER = "PAYAPPOINTMENTORDER";
export const SET_CREATEORDER = "CREATEORDER";
export const SET_PAYORDER = "PAYORDER";
