import Cookies from "js-cookie";

export const getParsedLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const saveLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const removeLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const getCookieParsed = (key, expectType = "array") => {
  try {
    const rawCookieVal = Cookies.get(key);
    return JSON.parse(rawCookieVal);
  } catch (error) {
    if (expectType === "array") {
      return [];
    }
    return null;
  }
};
