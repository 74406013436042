import React from "react";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import { Row, Col, Divider } from "antd";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import contactus from "../../assets/images/ContactUs.png";
import Decorative1 from "../../assets/images/Decorative1.png";
import KASTURINAGAR01 from "../../assets/images/KASTURINAGAR01.png";
import kasturiStudioImg from "../../assets/images/studio1.jpg";
import jayamahalStudioImg from "../../assets/images/studio2.JPG";
import studio3 from "../../assets/images/studio3.JPG";
// import jayamahalStudioImg from "../../assets/images/studio1.jpg";

import "./index.css";

const OurStudio = () => {
  const settings = {
    arrows: true,
    nextArrow: (
      <span>
        <RightOutlined className="carousel-arrow" />
      </span>
    ),
    prevArrow: (
      <span>
        <LeftOutlined className="carousel-arrow" />
      </span>
    ),
  };
  return (
    <>
      <Navbar />
      <div className="ourStudio">
        <div className="page-title">
          <h1>
            <span className="page-title-border">Our</span> Studios
          </h1>
        </div>
        <div className="aboutus-box">
          <Row gutter={[20, 20]}>
            <Col xl={24} lg={24} className="ant-col-24">
              <h2>MEHENDI DELIGHT - Studio By SANIA @ KASTURINAGAR</h2>
            </Col>
            <Col
              style={{ Width: "100%" }}
              md={14}
              sm={14}
              lg={14}
              className="ant-col-24"
            >
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showThumbs={false}
                swipeable={true}
                emulateTouch={true}
              >
                <div className="kasturi-img">
                  <img src={kasturiStudioImg} alt="" />
                </div>
                <div className="kasturi-img">
                  <img src={KASTURINAGAR01} alt="" width="100%" />
                </div>
              </Carousel>
            </Col>
            <Col
              lg={10}
              md={10}
              className="ant-col-24"
              style={{ paddingBottom: "100px" }}
            >
              <div className="contact-us-parent-box">
                <div className="contact-us-child-box">
                  <img alt="" src={contactus} style={{ width: "100%" }} />
                </div>
                <Row style={{}} className="row-1">
                  <Col
                    xs={24}
                    md={3}
                    className="ant-col-24"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="contact-us-child-box">
                      <PhoneOutlined style={{ fontSize: "25px" }} />
                    </div>
                  </Col>
                  <Col xs={24} md={15} className="ant-col-24">
                    <div className="contact-us-child-box">
                      <h3 className="value-box">+91 8310756787</h3>
                    </div>
                  </Col>
                </Row>
                <Row style={{ padding: "40px 70px 0px 50px" }}>
                  <Col
                    xs={24}
                    md={3}
                    className="ant-col-24"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="contact-us-child-box">
                      <MailOutlined style={{ fontSize: "25px" }} />
                    </div>
                  </Col>
                  <Col xs={24} md={15} className="ant-col-24">
                    <div className="contact-us-child-box">
                      <h3 className="value-box">
                        <a
                          style={{ color: "black" }}
                          href="mailto: info@mehendidelight.com"
                        >
                          info@mehendidelight.com
                        </a>
                      </h3>
                    </div>
                  </Col>
                </Row>
                <Row style={{ padding: "40px 70px 70px 50px" }}>
                  <Col
                    md={3}
                    className="ant-col-24"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="contact-us-child-box">
                      <EnvironmentOutlined style={{ fontSize: "25px" }} />
                    </div>
                  </Col>
                  <Col md={21} lg={18} xl={16} className="ant-col-24">
                    <div className="contact-us-child-box">
                      <h3 className="value-box">
                        2M-443, Sheetal Building, Ground Floor, 2nd Main Rd,
                        Kasturi Nagar Main Rd, next to Baskin Robbins, Lal
                        Bahadur Nagar, East of NGEF Layout, Kasturi Nagar,
                        Bengaluru, Karnataka 560043
                      </h3>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <Divider />
        <div className="aboutus-box">
          <Row gutter={[20, 20]}>
            <Col xl={24} lg={24} className="ant-col-24">
              <h2>MEHENDI DELIGHT - Studio By SANIA @ JAYAMAHAL EXTN</h2>
            </Col>
            <Col
              style={{ Width: "100%" }}
              md={14}
              sm={14}
              lg={14}
              className="ant-col-24"
            >
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showThumbs={false}
                swipeable={true}
                emulateTouch={true}
              >
                <div className="jayamahal-img">
                  <img src={jayamahalStudioImg} alt="" width="100%" />
                </div>

                <div className="jayamahal-img">
                  <img src={KASTURINAGAR01} alt="" />
                </div>
              </Carousel>
            </Col>
            <Col
              lg={10}
              md={10}
              className="ant-col-24"
              style={{ paddingBottom: "100px" }}
            >
              <div className="contact-us-parent-box">
                <div className="contact-us-child-box">
                  <img alt="" src={contactus} style={{ width: "100%" }} />
                </div>
                <Row style={{ padding: "20px 70px 0px 50px" }}>
                  <Col
                    xs={24}
                    md={4}
                    className="ant-col-24"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="contact-us-child-box">
                      <PhoneOutlined style={{ fontSize: "25px" }} />
                    </div>
                  </Col>
                  <Col xs={24} md={12} className="ant-col-24">
                    <div className="contact-us-child-box">
                      <h3 className="value-box" >+91 8310756787</h3>
                    </div>
                  </Col>
                </Row>
                <Row style={{ padding: "40px 70px 0px 50px" }}>
                  <Col
                    md={4}
                    className="ant-col-24"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="contact-us-child-box">
                      <MailOutlined style={{ fontSize: "25px" }} />
                    </div>
                  </Col>
                  <Col md={12} className="ant-col-24">
                    <div className="contact-us-child-box">
                      <h3 className="value-box">
                        <a
                          style={{ color: "black" }}
                          href="mailto: info@mehendidelight.com"
                        >
                          info@mehendidelight.com
                        </a>
                      </h3>
                    </div>
                  </Col>
                </Row>
                <Row style={{ padding: "40px 70px 70px 50px" }}>
                  <Col
                    md={4}
                    className="ant-col-24"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="contact-us-child-box">
                      <EnvironmentOutlined style={{ fontSize: "25px" }} />
                    </div>
                  </Col>
                  <Col md={12} className="ant-col-24">
                    <div className="contact-us-child-box">
                      <h3 className="value-box">
                        Ground Floor, 45, 5th Main Rd, Jayamahal Extension,
                        Jayamahal, Bengaluru, Karnataka 560046
                      </h3>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
        <Row style={{ padding: "0px 0px 50px" }}>
          <Col className="ant-col-24 decorative">
            <div>
              <img src={Decorative1} alt="" />
            </div>
          </Col>
        </Row>
        <div className="page-title">
          <h1>
            <span className="page-title-border">Corp</span>orate Office Address
          </h1>
        </div>
        <div className="aboutus-box">
          <Row gutter={[20, 20]}>
            <Col
              lg={10}
              md={10}
              className="ant-col-24"
              style={{ paddingBottom: "100px" }}
            >
              <div className="contact-us-parent-box">
                <div className="contact-us-child-box">
                  <img alt="" src={contactus} style={{ width: "100%" }} />
                </div>
                <Row style={{ padding: "0px 20px 40px 50px" }} className="">
                  <Col md={18} className="ant-col-24">
                    <div className="contact-us-child-box text-div">
                      <h3>
                        Company Name : SAZ DELIGHT DESIGNING IND PVT LTD
                        <br />
                        Brand Name : Mehendi Delight{" "}
                      </h3>
                    </div>
                  </Col>
                </Row>
                <Row className="address-row">
                  <Col
                    xs={24}
                    md={4}
                    className="ant-col-24"
                    style={{ marginTop: "0px" }}
                  >
                    <div className="contact-us-child-box">
                      <PhoneOutlined style={{ fontSize: "25px" }} />
                    </div>
                  </Col>
                  <Col xs={24} md={12} className="ant-col-24">
                    <div className="contact-us-child-box">
                      <h3 className="value-box">+91 8310756787</h3>
                    </div>
                  </Col>
                </Row>
                <Row style={{ padding: "20px 70px 10px 50px" }}>
                  <Col
                    xs={24}
                    md={4}
                    className="ant-col-24"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="contact-us-child-box">
                      <MailOutlined style={{ fontSize: "25px" }} />
                    </div>
                  </Col>
                  <Col xs={24} md={12} className="ant-col-24">
                    <div className="contact-us-child-box">
                      <h3 className="value-box">
                        <a
                          style={{ color: "black" }}
                          href="mailto: info@mehendidelight.com"
                        >
                          info@mehendidelight.com
                        </a>
                      </h3>
                    </div>
                  </Col>
                </Row>
                <Row style={{ padding: "20px 70px 70px 50px" }}>
                  <Col
                    xs={24}
                    md={4}
                    className="ant-col-24"
                    style={{ marginTop: "15px" }}
                  >
                    <div className="contact-us-child-box">
                      <EnvironmentOutlined style={{ fontSize: "25px" }} />
                    </div>
                  </Col>
                  <Col xs={24} md={12} className="ant-col-24">
                    <div className="contact-us-child-box">
                      <h3 className="value-box">
                        No.219, 3rd cross Ambika Corner Complex, NGEF Layout,
                        Kasturi Nagar, Bengaluru-560043
                      </h3>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              style={{ Width: "100%" }}
              md={14}
              sm={14}
              lg={14}
              className="ant-col-24"
            >
              <Carousel
                infiniteLoop={true}
                autoPlay={true}
                showThumbs={false}
                swipeable={true}
                emulateTouch={true}
              >
                <div className="kasturi-img">
                  <img src={studio3} alt="" width="100%" />
                </div>
                <div className="corporate-office-img">
                  <img src={jayamahalStudioImg} alt="" width="100%" />
                </div>
              </Carousel>
            </Col>
          </Row>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default OurStudio;
