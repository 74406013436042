import { legacy_createStore as createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducer';

export default function configureStore() {
    return createStore(
        rootReducer,
        compose(
            applyMiddleware(thunk)
        )
    )
};