import {
  SET_ADDRESS,
  SET_CUSTOMERPROFILE,
  SET_GETESTIMATIONCHARGES,
  SET_ARTISTRATING,
  SET_GETCUSTOMERORDER,
  SET_GETPRODUCT,
  SET_GETPRODUCTBYID,
  SET_EVENTBASED,
  PENDING_PAYMENT_REQUESTED,
  PENDING_PAYMENT_FULFILLED,
  PENDING_PAYMENT_REJECTED,
  SET_ESTIMATIONDETAILSTOGUEST,
  SET_BOOKAPPOINTMENTNEW,
  SET_GETENQUIRYFORM,
} from "../action/types";

const initialState = {
  GetCustomerProfile: {},
  GetEstimationCharges: [],
  pendingPayments: [],
  getProducts: [],
  getProductById: [],
  pendingPaymentLoading: false,
  pendingPaymentError: null,
  estimationDetailsForGuest: {},
  bookAppointmentNew: [],
  bookingAppointmentRequested: false,
  bookAppointmentFulfilled: false,
  bookAppointmentRejected: false,
  paymentLink: null,
  paymentLinkRequested: false,
  paymentLinkRejected: false,
  paymentLinkFulfilled: false,
  setGetEnquiryForm: [],
  placeOrderRequested: false,
  placeOrderFulfilled: false,
  placeOrderRejected: false,

  finalPaymentRequested: false,
  finalPaymentFulfilled: false,
  finalPaymentRejected: false,
};

export default function customerReducer(
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case SET_ADDRESS:
      return {
        ...state,
        ...payload,
      };
    case SET_CUSTOMERPROFILE:
      return {
        ...state,
        GetCustomerProfile: payload,
      };
    case SET_GETESTIMATIONCHARGES:
      return {
        ...state,
        GetEstimationCharges: payload,
      };
    case SET_ARTISTRATING:
      return {
        ...state,
        ...payload,
      };
    case SET_GETCUSTOMERORDER:
      return {
        ...state,
        ...payload,
      };
    case SET_GETPRODUCT:
      return {
        ...state,
        getProducts: payload,
      };
    case SET_GETPRODUCTBYID:
      return {
        ...state,
        getProductById: payload,
      };
    case SET_EVENTBASED:
      return {
        ...state,
        ...payload,
      };

    case PENDING_PAYMENT_REQUESTED:
      return {
        ...state,
        pendingPaymentLoading: true,
      };
    case PENDING_PAYMENT_FULFILLED:
      return {
        ...state,
        pendingPayments: payload,
        pendingPaymentLoading: false,
      };
    case PENDING_PAYMENT_REJECTED:
      return {
        ...state,
        pendingPaymentLoading: false,
        pendingPaymentError: payload,
      };
    case SET_ESTIMATIONDETAILSTOGUEST:
      return {
        ...state,
        estimationDetailsForGuest: payload,
      };
    case SET_BOOKAPPOINTMENTNEW:
      return {
        ...state,
        bookAppointmentFulfilled: true,
        bookingAppointmentRequested: false,
      };
    case "BOOK_APPOINTMENT_REJECTED":
      return {
        ...state,
        bookAppointmentFulfilled: false,
        bookAppointmentRejected: true,
        bookingAppointmentRequested: false,
      };
    case "BOOK_APPOINTMENT_REQUESTED":
      return {
        ...state,
        bookAppointmentFulfilled: false,
        bookAppointmentRejected: false,
        bookingAppointmentRequested: true,
      };
    case SET_GETENQUIRYFORM:
      return {
        ...state,
        setGetEnquiryForm: payload,
      };
    case "PAYMENT_LINK_REQUESTED":
      return {
        ...state,
        paymentLinkRequested: true,
        paymentLinkFulfilled: false,
      };
    case "PAYMENT_LINK_FULFILLED":
      return {
        ...state,
        paymentLink: payload,
        paymentLinkRequested: false,
        paymentLinkRejected: false,
        paymentLinkFulfilled: true,
      };
    case "PAYMENT_LINK_REJECTED":
      return {
        ...state,
        paymentLink: null,
        paymentLinkRequested: false,
        paymentLinkRejected: true,
        paymentLinkFulfilled: false,
      };

    case "PLACE_ORDER_REQUESTED":
      return {
        ...state,
        placeOrderRequested: true,
        placeOrderFulfilled: false,
      };
    case "PLACE_ORDER_FULFILLED":
      return {
        ...state,
        placeOrderRequested: false,
        placeOrderRejected: false,
        placeOrderFulfilled: true,
      };
    case "PLACE_ORDER_REJECTED":
      return {
        ...state,
        placeOrderRequested: false,
        placeOrderRejected: true,
        placeOrderFulfilled: false,
      };
    case "FINAL_PAYMENT_REQUESTED":
      return {
        ...state,
        finalPaymentRequested: true,
        finalPaymentFulfilled: false,
      };
    case "FINAL_PAYMENT_FULFILLED":
      return {
        ...state,
        finalPaymentRequested: false,
        finalPaymentRejected: false,
        finalPaymentFulfilled: true,
      };
    case "FINAL_PAYMENT_REJECTED":
      return {
        ...state,
        finalPaymentRequested: false,
        finalPaymentRejected: true,
        finalPaymentFulfilled: false,
      };
    default:
      return state;
  }
}
