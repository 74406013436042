import React from "react";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import "./index.css";
import { useParams } from "react-router";
import Cookies from "js-cookie";

const PaymentStatus = () => {
  const { type, status } = useParams();
  if (status.toUpperCase() === "SUCCESS") {
    switch (type.toUpperCase()) {
      case "BOOKAPPOINTMENT":
        localStorage.removeItem("EstimationDetails");
        break;
      case "PRODUCTPURCHASE":
        Cookies.remove("cd_all");
        break;
      default:
        break;
    }
  }
  return (
    <>
      <Navbar />
      <div style={{ textAlign: "center" }}>
        <h1>Payment {status.toUpperCase()}</h1>
      </div>
      <Footer />
    </>
  );
};
export default PaymentStatus;
