import { combineReducers } from "redux";
import adminReducer from "./adminReducer";
import artistReducer from "./artistReducer";
import websiteReducer from "./websiteReducer";
import authReducer from "./authReducer";
import customerReducer from "./customerReducer";
import cartReducer from "./cartReducer";

export default combineReducers({
  admin: adminReducer,
  artist: artistReducer,
  website: websiteReducer,
  auth: authReducer,
  customer: customerReducer,
  cartReducer: cartReducer,
});
