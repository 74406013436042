import React from "react";
import Routes from "./routes";
import { Provider } from "react-redux";
import configureStore from "./store";
import "antd/dist/antd";
import "./App.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const App = (_) => {
  const store = configureStore();
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  );
};

export default App;
