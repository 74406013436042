import React, { lazy, Suspense } from "react";
import "./App.css";
import { LoadingOutlined } from "@ant-design/icons";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import authCheck, { authCheckNoRole } from "./AuthChecker";
import ProtectedRoute from "./ProtectedRoute";
import NotFound from "./components/NotFound";
import OurStudio from "./components/website/ourStudio";
import PaymentStatus from "./components/Bookings/paymentStatus";

//Website
const LandingPage = lazy(() => import("./components/website/landingPage"));
const AboutUs = lazy(() => import("./components/website/aboutUs"));
const Services = lazy(() => import("./components/website/services"));
const Products = lazy(() => import("./components/website/products"));
const ProductInfo = lazy(() => import("./components/website/ProductInfo"));
const ContactUs = lazy(() => import("./components/website/contactUs"));

//Bookings
const AppointmentBooking = lazy(() =>
  import("./components/Bookings/appointmentBooking")
);
const EstimatePriceSelection = lazy(() =>
  import("./components/Bookings/estimatePrice")
);
const Payment = lazy(() => import("./components/Bookings/payment"));
const PaymentRedirection = lazy(() =>
  import("./components/Bookings/paymentRedirection")
);

const TestPayment = lazy(() => import("./components/Bookings/TestPayment"));

//customer
const AddAddress = lazy(() => import("./components/customer/addAddress"));
const CustomerPage = lazy(() => import("./components/customer/customerPage"));

//Cart
const AddToCart = lazy(() => import("./components/Cart/addToCart"));
const ProceedToPay = lazy(() => import("./components/Cart/proceedToPay"));

//Login & Signup
const Login = lazy(() => import("./components/LoginSignupPages/login"));
const CreateAccount = lazy(() =>
  import("./components/LoginSignupPages/createAccount")
);
const ForgotPwd = lazy(() =>
  import("./components/LoginSignupPages/forgotPassword")
);
const ValidateOtp = lazy(() =>
  import("./components/LoginSignupPages/validateOtp")
);

//Artist
const ProfileDetails = lazy(() => import("./components/artist/profileDetails"));
const ArtistPackages = lazy(() =>
  import("./components/artist/assignedPackages/Tab")
);
const EditPackage = lazy(() =>
  import("./components/artist/assignedPackages/editPackageDetails")
);

//Admin index route
const Admin = lazy(() => import("./components/admin/index"));

const Portfolio = lazy(() => import("./components/artist/artistPortfolio"));

const webRoute = (_) => (
  <>
    <Suspense
      fallback={
        <LoadingOutlined
          style={{
            fontSize: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20%",
          }}
          spin
        />
      }
    >
      <Router basename="">
        <Routes>
          {/*Website routes*/}
          <Route path="/" element={<LandingPage />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product-info/:productID" element={<ProductInfo />} />
          <Route path="/ourstudio" element={<OurStudio />} />
          <Route path="/contactus" element={<ContactUs />} />

          {/*customer*/}
          <Route
            path="/customer"
            element={
              <ProtectedRoute
                redirectPath="/login"
                isAllowed={authCheck("CUSTOMER")}
              >
                <CustomerPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/addAddress"
            element={
              <ProtectedRoute
                redirectPath="/login"
                isAllowed={authCheck("CUSTOMER")}
              >
                <AddAddress />
              </ProtectedRoute>
            }
          />

          {/*Bookings*/}
          <Route path="/appointmentbooking" element={<AppointmentBooking />} />
          <Route path="/estimateprice" element={<EstimatePriceSelection />} />
          <Route
            path="/paymentStatus/:type/:status"
            element={<PaymentStatus />}
          />
          {/* <Route
            path="/estimateprice"
            element={
              <ProtectedRoute
                redirectPath="/login"
                isAllowed={authCheck("CUSTOMER")}
              >
                <EstimatePriceSelection />
              </ProtectedRoute>
            }
          /> */}
          <Route
            path="/payment-redirection"
            element={
              <ProtectedRoute
                redirectPath="/login"
                isAllowed={authCheck("CUSTOMER")}
              >
                <PaymentRedirection />
              </ProtectedRoute>
            }
          />
          <Route path="/test-payment" element={<TestPayment />} />

          {/*Cart*/}
          <Route path="/addtocart" element={<AddToCart />} />
          <Route
            path="/proceedtopay"
            element={
              <ProtectedRoute
                redirectPath="/login"
                isAllowed={authCheck("CUSTOMER")}
              >
                <ProceedToPay />
              </ProtectedRoute>
            }
          />

          {/*Login & Signup routes*/}
          <Route
            path="/login"
            element={
              <ProtectedRoute redirectPath="/" isAllowed={authCheckNoRole()}>
                <Login />
              </ProtectedRoute>
            }
          />
          <Route path="/createaccount/:type" element={<CreateAccount />} />
          <Route path="/forgotpwd" element={<ForgotPwd />} />
          <Route path="/validate-otp" element={<ValidateOtp />} />

          {/*Artist*/}

          <Route
            path="/profiledetails"
            element={
              <ProtectedRoute
                redirectPath="/login"
                isAllowed={authCheck("ARTIST")}
              >
                <ProfileDetails />
              </ProtectedRoute>
            }
          />
          <Route
            path="/artistpackages"
            element={
              <ProtectedRoute
                redirectPath="/login"
                isAllowed={authCheck("ARTIST")}
              >
                <ArtistPackages />
              </ProtectedRoute>
            }
          />
          <Route
            path="/editpackage/:appointmentId"
            element={
              <ProtectedRoute
                redirectPath="/login"
                isAllowed={authCheck("ARTIST")}
              >
                <EditPackage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/profiledetails/portfolio"
            element={
              <ProtectedRoute
                redirectPath="/login"
                isAllowed={authCheck("ARTIST")}
              >
                <Portfolio />
              </ProtectedRoute>
            }
          />

          {/*Admin index route*/}
          <Route path="/admin/*" element={<Admin />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </Suspense>
  </>
);
export default webRoute;
