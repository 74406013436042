import jwt_decode from "jwt-decode";

const decodeToken = (token) => {
  try {
    return jwt_decode(token);
  } catch (error) {
    return null;
  }
};
const authCheck = (role) => {
  const token = localStorage.getItem("token");
  let decoded = decodeToken(token);
  return checkRole(decoded, role);
};
const checkRole = (decodedToken, role) => {
  return decodedToken && decodedToken.role === role;
};

export const authCheckNoRole = () => {
  const token = localStorage.getItem("token");
  let decoded = decodeToken(token);
  return decoded === null;
};

export default authCheck;
