import { NavLink } from "react-router-dom";

const NotFound = () => {
  return (
    <>
      <h2>Page not found</h2>
      <div>
        <NavLink to="/">Click here to go back to home</NavLink>
      </div>
    </>
  );
};

export default NotFound;
