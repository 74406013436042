import {
  SET_LOGIN,
  SET_CREATE_ACCOUNT,
  SET_FORGOT_PWD,
  SET_VALIDATE_OTP,
  SET_CUSTOMER_LOGOUT,
} from "../action/types";

const initialState = {
  isAuthenticated: false,
  isAccountCreated: false,
  user: {},
  forgotpwd: {},
  validateOtp: {},
  isRegistrationLoading: false,
};

export default function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_LOGIN:
      return {
        ...state,
        ...payload,
      };
    case SET_CREATE_ACCOUNT:
      return {
        ...state,
        isRegistrationLoading: false,
      };
    case SET_FORGOT_PWD:
      return {
        ...state,
        ...payload,
      };
    case SET_VALIDATE_OTP:
      return {
        ...state,
        ...payload,
      };
    case SET_CUSTOMER_LOGOUT:
      return {
        initialState,
      };

    case "CUSTOMER_REGISTRATION_REQUESTED":
      return {
        ...state,
        isRegistrationLoading: true,
      };

    case "CUSTOMER_REGISTRATION_FULFILLED":
      return {
        ...state,
        isRegistrationLoading: false,
      };

    case "CUSTOMER_REGISTRATION_REJECTED":
      return {
        ...state,
        isRegistrationLoading: false,
      };

    default:
      return state;
  }
}
