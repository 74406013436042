import React, { useEffect, useState } from "react";
import { Row, Col, Button, Badge, Dropdown, Space, Avatar, Modal } from "antd";
import { NavLink } from "react-router-dom";
import {
  ShoppingCartOutlined,
  CloseOutlined,
  MenuOutlined,
  UserOutlined,
} from "@ant-design/icons";
import "./navbar.css";
import Logo from "../../assets/images/Logo.png";
import { useSelector } from "react-redux";
import { getCookieParsed, getLocalStorage } from "../../utils/storageHelper";
import authCheck from "../../AuthChecker";
import Cookies from "js-cookie";

const Navbar = () => {
  const cartReducer = useSelector((state) => state.cartReducer);
  const auth = useSelector((state) => state.auth);
  const [cartCount, setCartCount] = useState(0);

  useEffect(() => {
    if (getCookieParsed("cd_all")) {
      setCartCount(getCookieParsed("cd_all").length);
    }
  }, [cartReducer]);
  const success = () => {
    Modal.success({
      content: "Welcome to the world of Henna",
    });
  };
  useEffect(() => {
    const isWelcomeMessage = localStorage.getItem("wcm");
    if (isWelcomeMessage) {
      success();
      localStorage.removeItem("wcm");
    }
  }, []);

  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  const fnLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("fullName");
    localStorage.removeItem("EstimationDetails");
    Cookies.remove("cd_all");
    window.location.href = "/";
  };

  let menuCustomer = [
    {
      key: "1",
      label: (
        <Space>
          <NavLink style={{ all: "unset" }} to="/customer">
            My Profile
          </NavLink>
        </Space>
      ),
    },
    {
      key: "2",
      label: (
        <Space>
          <span onClick={fnLogout}>Logout</span>
        </Space>
      ),
    },
  ];

  let menuAdmin = [
    {
      key: "1",
      label: (
        <Space>
          <NavLink style={{ all: "unset" }} to="/admin/dashboard">
            Dashboard
          </NavLink>
        </Space>
      ),
    },
    {
      key: "2",
      label: (
        <Space>
          <span onClick={fnLogout}>Logout</span>
        </Space>
      ),
    },
  ];

  const menuArtist = [
    {
      key: "1",
      label: (
        <Space>
          <NavLink style={{ all: "unset" }} to="/artistpackages">
            Dashboard
          </NavLink>
        </Space>
      ),
    },
    {
      key: "2",
      label: (
        <Space>
          <span onClick={fnLogout}>Logout</span>
        </Space>
      ),
    },
  ];

  const [items, setCurrentMenu] = useState([]);

  useEffect(() => {
    if (authCheck("ARTIST")) {
      setCurrentMenu(menuArtist);
    }
    if (authCheck("CUSTOMER")) {
      setCurrentMenu(menuCustomer);
    }
    if (authCheck("ADMIN")) {
      setCurrentMenu(menuAdmin);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MenuItems = () => {
    return (
      <ul className="menu">
        <li className="menu-item" onClick={handleClick}>
          <NavLink to="/">Home</NavLink>
        </li>
        <span className="divider"></span>
        <li className="menu-item" onClick={handleClick}>
          <NavLink to="/about">About Us</NavLink>
        </li>
        <span className="divider"></span>

        <li className="menu-item" onClick={handleClick}>
          <NavLink to="/services">Occassions</NavLink>
        </li>
        <span className="divider"></span>

        <li className="menu-item" onClick={handleClick}>
          <NavLink to="/products">Products</NavLink>
        </li>
        <span className="divider"></span>

        <li className="menu-item" onClick={handleClick}>
          <NavLink to="/ourstudio">Our Studios</NavLink>
        </li>
        <span className="divider"></span>

        <li className="menu-item no-border" onClick={handleClick}>
          <NavLink to="/contactus">Contact Us</NavLink>
        </li>
        {!authCheck("ADMIN") && !authCheck("ARTIST") && (
          <>
            <li
              className="no-border"
              onClick={handleClick}
              style={{ listStyle: "none" }}
            >
              <a href="/appointmentbooking">
                <Button>Book Appointment</Button>
              </a>
            </li>
            <li
              className="no-border"
              onClick={handleClick}
              style={{ listStyle: "none" }}
            >
              <NavLink to="/addtocart">
                <Badge count={cartCount}>
                  <Button>
                    <ShoppingCartOutlined className="cart" />
                    Cart
                  </Button>
                </Badge>
              </NavLink>
            </li>
          </>
        )}

        {!auth.isAuthenticated && !getLocalStorage("token") ? (
          <>
            <li
              className="no-border"
              onClick={handleClick}
              style={{ listStyle: "none" }}
            >
              <NavLink to="/login">
                <Button>Login</Button>
              </NavLink>
            </li>
          </>
        ) : (
          <>
            <li className="menu-item-profile-icon no-border" >
              <Dropdown
                menu={{
                  items,
                }}
              >
                <a href="/#" onClick={(e) => e.preventDefault()} >
                  <Space>
                    <Avatar
                      size={{
                        xs: 24,
                        sm: 32,
                        md: 32,
                        lg: 32,
                        xl: 32,
                        xxl: 32,
                      }}
                      icon={<UserOutlined />}
                      style={{
                        border: "2px solid #ffffff",
                        borderRadius: "50%",
                      }}
                    />
                  </Space>
                </a>
              </Dropdown>
            </li>
          </>
        )}
      </ul>
    );
  };

  return (
    <>
      <div>
        <Row>
          <Col className="ant-col-24 head-navbar">
            <nav>
              <NavLink to="/">
                <img
                  src={Logo}
                  alt=""
                  className="logo-img-size"
                  style={{ minWidth: "70px" }}
                />
              </NavLink>
            </nav>
          </Col>
        </Row>
        <Row>
          <Col className="ant-col-24 main-navbar">
            <div className="menu-responsive">
              <div className="desktop-menu">
                <MenuItems />
              </div>
              <div className="mobile-menu">
                {!click ? (
                  <MenuOutlined
                  className="icon"
                    onClick={handleClick}
                    style={{ fontSize: "24px", cursor: "pointer" }}
                  />
                ) : (
                  <>
                    <CloseOutlined
                      onClick={handleClick}
                      className="icon"
                      style={{ fontSize: "24px", cursor: "pointer" }}
                    />
                    <MenuItems />
                  </>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Navbar;
