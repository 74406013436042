import React from "react";
import { Row, Col, Divider } from "antd";
import {
  LinkedinOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
} from "@ant-design/icons";
import Logo from "../../assets/images/Logo.png";
import "./footer.css";

const Footer = () => {
  return (
    <>
      <div className="footer">
        <Row>
          <Col
            md={8}
            className="ant-col-24"
            style={{ padding: "10px 0px 18px 0px" }}
          >
            <div>
              <img style={{ width: "100%" }} src={Logo} alt="" />
            </div>
            <div className="footer-logo-desc">
              Weaving intricate henna designs into life's most cherished moments
            </div>
          </Col>
          <Col md={2} className="ant-col-24"></Col>
          <Col
            md={4}
            className="ant-col-24"
            style={{ padding: "10px 0px 18px 20px" }}
          >
            <h3>Useful Links</h3>
            <div style={{ display: "block" }}>
              <p>
                <a className="copy-text-color" href="/">
                  Home
                </a>
              </p>
              <p>
                <a className="copy-text-color" href="/services">
                  Services
                </a>
              </p>
              {/* <p>Products</p> */}
              <p>
                <a className="copy-text-color" href="/about">
                  About Us
                </a>
              </p>
              {/* <p>Apply as Artist</p> */}
              <p>
                <a className="copy-text-color" href="/contactus">
                  Contact Us
                </a>
              </p>
            </div>
          </Col>
          <Col md={2} className="ant-col-24"></Col>
          <Col
            md={6}
            className="ant-col-24"
            style={{ padding: "10px 0px 18px 20px" }}
          >
            <h3>Contact Us</h3>
            <div style={{ display: "block" }}>
              <p>+91 8310756787</p>
              <p>
                <a
                  className="copy-text-color"
                  href="mailto: info@mehendidelight.com"
                >
                  info@mehendidelight.com
                </a>
              </p>
              <p>
                No.219, 3rd cross Ambika Corner Complex, NGEF Layout, Kasturi
                Nagar, Bengaluru-560043
              </p>
            </div>
          </Col>
        </Row>
        <div className="footer-border-bottom">
          <Divider style={{ border: "solid 0.1px #f2f2f2", margin: "0px" }} />
        </div>
        <Row>
          <Col md={8} className="ant-col-24">
            <a
              className="copy-text-color"
              rel="noreferrer"
              href="http://arligtechnologies.com/"
              target="_blank"
            >
              <p>© Arlig Technologies, Inc. 2023</p>
            </a>
          </Col>
          <Col md={6} className="ant-col-24"></Col>
          <Col md={8}>
            <ul>
              <li>
                <p>Follow us: </p>
              </li>
              <li>
                <div className="social-media">
                  <a
                    href="https://www.linkedin.com/in/mehendi-delight-studio-by-sania-5aa43a2a8/"
                    target="_blank"
                  >
                    <LinkedinOutlined />
                  </a>
                </div>
              </li>
              <li>
                <div className="social-media">
                  <a
                    href="https://www.facebook.com/mehendidelightstudiobysania/"
                    target="_blank"
                  >
                    <FacebookOutlined />
                  </a>
                </div>
              </li>
              <li>
                <div className="social-media">
                  <a
                    href="https://instagram.com/mehendi_delight?utm_medium=copy_link"
                    target="_blank"
                  >
                    <InstagramOutlined />
                  </a>
                </div>
              </li>
            </ul>
          </Col>
          <Col lg={1} md={2}></Col>
        </Row>
      </div>
    </>
  );
};
export default Footer;
