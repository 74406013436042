import {
  SET_ADDARTIST,
  SET_GETALLORDERS,
  SET_UPDATESTATUSORDER,
  SET_GETDELIVEREDORDERS,
  SET_GETCANCELLEDORDERS,
  SET_GETORDEROFCUSTOMER,
  SET_ASSIGN,
  SET_CREATEPRODUCT,
  SET_GETPRODUCT,
  SET_GETPRODUCTBYID,
  SET_GETPRODUCTBYCATEGORY,
  SET_GETSELECTEDPRODUCT,
  SET_GETALLAPPROVEDARTISTS,
  SET_ARTISTAPPROVEBYADMIN,
  SET_ARTISTREJECTBYADMIN,
  SET_GETPENDINGARTISTS,
  SET_GETARTISTS,
  SET_GETREJECTEDARTISTS,
  SET_GETAPPOINTMENTBYID,
  SET_GETUPDATEAPPOINTMENTDATE,
  SET_GETUNASSIGNEDAPPOINTMENTS,
  SET_GETCANCELLEDAPPOINTMENTS,
  SET_GETCOMPLETEDAPPOINTMENTS,
  SET_CANCELAPPOINTMENT,
  SET_GETPACKAGEDETAILSADMIN,
  SET_SUBMITAPPOINTMENTDETAILSBYADMIN,
  SET_CONTACTADMIN,
  SET_GETALLCUSTOMERS,
  SET_GETCUSTOMERDETAILSADMIN,
  SET_EDITSTOCK,
  SET_GETARTISTDETAILSBYID,
} from "../action/types";

const initialState = {
  isAdded: false,
  artistAdded: {},
  getUnassignedAppointments: [],
  getArtists: [],
  getCompletedAppointments: [],
  getPendingArtists: [],
  GetAllApprovedArtists: [],
  getRejectedArtists: [],
  getCancelledAppointments: [],
  getAppointmentsByID: [],
  updateAppointmentDate: [],
  packageDetails: [],
  getArtistLoding: false,
  getProducts: [],
  getProductById: [],
  GetProductsByCategory: [],
  GetSelectedProducts: [],
  GetAllOrders: [],
  GetDeliveredOrders: [],
  GetCancelledOrders: [],
  GetOrdersOfCustomer: [],
  getAllCustomers: [],
  getCustomersDetailsAdmin: [],
  getArtistAetailsByID: [],
};

export default function adminReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_ADDARTIST:
      return {
        ...state,
        ...payload,
      };
    case SET_GETALLORDERS:
      return {
        ...state,
        GetAllOrders: payload,
      };
    case SET_UPDATESTATUSORDER:
      return {
        ...state,
        ...payload,
      };
    case SET_GETDELIVEREDORDERS:
      return {
        ...state,
        GetDeliveredOrders: payload,
      };
    case SET_GETCANCELLEDORDERS:
      return {
        ...state,
        GetCancelledOrders: payload,
      };
    case SET_GETORDEROFCUSTOMER:
      return {
        ...state,
        GetOrdersOfCustomer: payload,
      };
    case SET_ASSIGN:
      return {
        ...state,
        ...payload,
      };
    case SET_CREATEPRODUCT:
      return {
        ...state,
        ...payload,
      };
    case SET_GETPRODUCT:
      return {
        ...state,
        getProducts: payload,
      };
    case SET_EDITSTOCK:
      return {
        ...state,
        editStock: payload,
      };
    case SET_GETPRODUCTBYID:
      return {
        ...state,
        getProductById: payload,
      };
    case SET_GETPRODUCTBYCATEGORY:
      return {
        ...state,
        GetProductsByCategory: payload,
      };
    case SET_GETSELECTEDPRODUCT:
      return {
        ...state,
        GetSelectedProducts: payload,
      };
    case SET_GETALLCUSTOMERS:
      return {
        ...state,
        getAllCustomers: payload,
      };
    case SET_GETCUSTOMERDETAILSADMIN:
      return {
        ...state,
        getCustomersDetailsAdmin: payload,
      };
    case SET_GETALLAPPROVEDARTISTS:
      return {
        ...state,
        GetAllApprovedArtists: payload,
      };
    case SET_ARTISTAPPROVEBYADMIN:
      return {
        ...state,
        ...payload,
      };
    case SET_ARTISTREJECTBYADMIN:
      return {
        ...state,
        ...payload,
      };
    case SET_GETPENDINGARTISTS:
      return {
        ...state,
        getPendingArtists: payload,
      };
    case SET_GETARTISTS:
      return {
        ...state,
        getArtists: payload,
        getArtistLoding: false,
      };
    case "SET_GETARTISTSREQUESTED":
      return {
        ...state,
        getArtistLoding: true,
      };
    case SET_GETREJECTEDARTISTS:
      return {
        ...state,
        getRejectedArtists: payload,
      };
    case SET_GETAPPOINTMENTBYID:
      return {
        ...state,
        getAppointmentsByID: payload,
      };
    case SET_GETUPDATEAPPOINTMENTDATE:
      return {
        ...state,
        updateAppointmentDate: payload,
      };
    case SET_GETUNASSIGNEDAPPOINTMENTS:
      return {
        ...state,
        getUnassignedAppointments: payload,
      };
    case SET_GETCANCELLEDAPPOINTMENTS:
      return {
        ...state,
        getCancelledAppointments: payload,
      };
    case SET_GETCOMPLETEDAPPOINTMENTS:
      return {
        ...state,
        getCompletedAppointments: payload,
      };
    case SET_CANCELAPPOINTMENT:
      return {
        ...state,
        ...payload,
      };
    case SET_GETPACKAGEDETAILSADMIN:
      return {
        ...state,
        ...payload,
        packageDetails: payload,
      };
    case SET_SUBMITAPPOINTMENTDETAILSBYADMIN:
      return {
        ...state,
        ...payload,
      };
    case SET_GETARTISTDETAILSBYID:
      return {
        ...state,
        getArtistAetailsByID: payload,
      };
    case SET_CONTACTADMIN:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
