import Cookies from "js-cookie";
import { getCookieParsed } from "../utils/storageHelper";
const cart = getCookieParsed("cd_all");

const cartReducer = (state = cart, { type, payload }) => {
  const product = payload;
  switch (type) {
    case "ADD_PRODUCT":
      const exist = state.find((x) => x._id === product._id);
      if (exist) {
        const allItems = state.map((x) =>
          x._id === product._id ? { ...x, qty: x.unit + 1 } : x
        );
        Cookies.set("cd_all", JSON.stringify(allItems), { expires: 7 });
        return allItems;
      } else {
        const product = payload;
        const allItems = [
          ...state,
          {
            ...product,
          },
        ];
        Cookies.set("cd_all", JSON.stringify(allItems), { expires: 7 });
        return allItems;
      }

    case "GET_PRODUCT":
      return getCookieParsed("cd_all");

    case "DELETE_PRODUCT":
      const productsAfterDelete = state.filter((x) => x._id !== payload._id);
      Cookies.set("cd_all", JSON.stringify(productsAfterDelete), {
        expires: 7,
      });
      return productsAfterDelete;

    case "UPDATE_PRODUCT_QUANTITY":
      const products = state.map((product) => {
        if (product._id === payload._id) {
          product.unit = payload.unit;
        }
        return product;
      });
      Cookies.set("cd_all", JSON.stringify(products), { expires: 7 });
      return products;

    default:
      return state;
  }
};
export default cartReducer;
