import {
  SET_GETARTIST,
  SET_ARTISTAVAILABILITY,
  SET_ARTISTPROFILE,
  SET_GETPACKAGEDETAILS,
  SET_APPOINTMENTDETAILS,
  SET_SUBMITAPPOINTMENTDETAILS,
  SET_ADDPERSONWITHPACKAGE,
  SET_UPDATEARTISTPROFILE,
  SET_GETPACKAGEDETAILSLOADING,
  SET_PACKAGEDETAILSLOADINGERROR,
} from "../action/types";

const initialState = {
  getArtist: null,
  artistProfile: null,
  packageDetails: null,
  artistAvailability: null,
  isPackageDetailsLoading: false,
  isPackageDetailsError: null,
  artistProfileLoading: false,
};

export default function artistReducer(state = initialState, { type, payload }) {
  switch (type) {
    case SET_GETARTIST:
      return {
        ...state,
        getArtist: payload,
      };
    case SET_ARTISTAVAILABILITY:
      return {
        ...state,
        artistAvailability: payload,
      };
    case SET_ARTISTPROFILE:
      return {
        ...state,
        artistProfile: payload,
        artistProfileLoading: false,
      };
    case "SET_ARTISTPROFILE_REQUESTED":
      return {
        ...state,
        artistProfileLoading: true,
      };

    case SET_GETPACKAGEDETAILS:
      return {
        ...state,
        packageDetails: payload,
        isPackageDetailsLoading: false,
      };
    case SET_GETPACKAGEDETAILSLOADING:
      return {
        ...state,
        isPackageDetailsLoading: true,
      };

    case SET_PACKAGEDETAILSLOADINGERROR:
      return {
        ...state,
        isPackageDetailsLoading: false,
        isPackageDetailsError: payload,
      };
    case SET_APPOINTMENTDETAILS:
      return {
        ...state,
        ...payload,
      };
    case SET_SUBMITAPPOINTMENTDETAILS:
      return {
        ...state,
        ...payload,
      };
    case SET_ADDPERSONWITHPACKAGE:
      return {
        ...state,
        ...payload,
      };
    case SET_UPDATEARTISTPROFILE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
